<template>
  <edit-template class="edit" @confirm="submit" :confirm-loading="loading" type="card">
    <edit-card title="教材基础信息">
      <el-form :model="form" ref="form" label-width="120rem" :rules="rules">
<!--        所属学科:select-->
        <el-form-item label="所属学科" prop="subject_id">
          <el-select v-model="form.subject_id" placeholder="请选择" @change="getData()">
            <el-option
              v-for="item in subjectData"
              :key="item.id"
              :label="item.subject_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
<!--        应用学段:radio-->
        <el-form-item label="应用学段" prop="apply_type">
          <el-radio-group v-model="form.apply_type" @change="getData()">
            <el-radio :label="0">初中</el-radio>
            <el-radio :label="1">高中</el-radio>
          </el-radio-group>
        </el-form-item>
<!--        教材版本:select-->
        <el-form-item label="教材版本" prop="teach_version">
          <el-select v-model="form.teach_version" placeholder="请选择" :disabled="$tools.isEmpty([form.apply_type,form.subject_id],'||')" @change="getData('bookNames')">
            <el-option
              v-for="item in bookVersion"
              :key="item.id"
              :label="item.teach_version"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
<!--        教材名称:select-->
        <el-form-item label="教材名称" prop="teach_name">
          <el-select v-model="form.teach_name" placeholder="请选择" :disabled="$tools.isEmpty([form.apply_type,form.subject_id,form.teach_version],'||')">
            <el-option
              v-for="item in bookNames"
              :key="item.id"
              :label="item.teach_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </edit-card>
    <edit-card title="章节内容">
      <editable-tree
          ref="tree"
          allow-empty
          node-default="章节"
          :max-level="4"
          :allow-drag="allowDrag"
          :allow-drop="allowDrop"
          :level-rule="levelRule"
          :props="defaultProps"
          :data="data">

      </editable-tree>
    </edit-card>
  </edit-template>
</template>

<script>
import EditTemplate from "@/components/pages/Edit";
import EditCard from "@/components/pages/EditCard";
import EditableTree from "@/components/EditableTree";
import {getSubject} from "@/api/common";
import {getVersionList, searchVersion} from "@/views/research/textbook/textbook-version/utils/api";

export default {
  _config: {route: {path: "add", meta: {title: "新增",style:2}}},
  components: {EditTemplate,EditCard,EditableTree},
  data() {
    return {
      loading: false,
      // 所属学科数据
      subjectData:[],
      // 教材版本数据
      bookVersion:[],
      // 教材名称数据
      bookNames:[],
      defaultProps:{
        label:"article_name",
        children:"article_list"
      },
      levelRule:[
          // 一级子树
          {
            editable:false, // 是否可编辑
            onlyDeleteChild:true, // 是否只删除子节点
            maxLength:15,    // 子节点最大长度, 设为-1 表示不限制
          },
          // 二级子树
          {
            maxLength: 10
          },
          // 三级子树
          {
            maxLength: 5
          },

      ],
      form: {
        subject_id: null,
        apply_type: null,
        teach_version: null,
        teach_name: null,
      },
      // 表单验证规则
      rules: {
        subject_id: [
          {required: true, message: '请选择所属学科', trigger: 'change'},
        ],
        apply_type: [
          {required: true, message: '请选择应用学段', trigger: 'change'},
        ],
        teach_version: [
          {required: true, message: '请选择教材版本', trigger: 'change'},
        ],
        teach_name: [
          {required: true, message: '请选择教材名称', trigger: 'change'},
        ],
      },
      //章节内容数据
      data:[
        {
          article_name: '章节',
          key:this.$tools.GUID(),
          article_list: []
        }
      ],
    }
  },
  watch:{
    "form.subject_id"(val){
      this.setRootName();
    },
    "form.teach_name"(val){
      this.setRootName();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    log:console.log,
    init(){
      getSubject().then(res=>{
        this.subjectData = res.data;
      })
    },
    getData(key="bookVersion") {
      if (this.$tools.isEmpty([this.form.apply_type,this.form.subject_id],'||'))return;
      this[key] = [];

      this.form.teach_name = null;
      if (key === "bookVersion")this.form.teach_version = null;
      let teach_version = this.bookVersion.filter(item=>item.id===this.form.teach_version)[0];
      teach_version = teach_version?teach_version.teach_version:undefined;
      getVersionList({
        get_all:1,
        subject_id:this.form.subject_id,
        apply_type:this.form.apply_type,
        teach_version,
      }).then(res=>{
        this[key] = res.data;
      })
      // ...
    },
    submit() {
      this.$refs.form.validate(flag=>{
        if (flag) {
          this.loading = true;
          this.$tools.DFS(this.data,item=>{
            item.level=item._tier+1;
            if (item.article_list.length){
              item.article_list.forEach((node,index)=>{
                node.order = index;
              })
            }
          },{
            child:"article_list"
          })
          let article_basic_id = this.form.teach_name;
          this.$_axios2.post("api/video/add-article", {
            article_basic_id,
            article_list:this.data,
          },{logic:1}).then(res=>{
            this.$setPrevOperation("add");
            this.$router.back();
          }).catch(()=>this.loading=false);
        }
      })

    },
    // 设置根节点的名称
    setRootName(){
      let subject = (this.subjectData.find(item => item.id === this.form.subject_id) || {}).subject_name||"";
      let book = (this.bookNames.find(item => item.id === this.form.teach_name) || {}).teach_name||"";
      book = book ? `${book}-` : "";
      subject = subject ? `${subject}-` : "";
      this.data[0][this.defaultProps.label] = `${subject} ${book} 章节`
    },
    // 判断节点能否被放置
    allowDrop(draggingNode, dropNode, type) {
      let curParentId = draggingNode.parent.id;
      let parentId = dropNode.parent.id;
      return parentId === curParentId && type !== 'inner';
    },
    // 判断节点能否被拖拽
    allowDrag(draggingNode) {
      return draggingNode.level !== 1;
    }

  }
}
</script>

<style lang="scss" scoped>
.edit{

}
</style>
